<template>
  <div class="root" :style="ctaColors" tabindex="0" @keyup.esc="closeModal">
    <!-- Used by yotpo loyalty -->
    <client-only>
      <div
        v-if="account !== null && account.isLoggedIn"
        :key="accountKey"
        style="display: none"
      >
        <div
          id="swell-customer-identification"
          :data-authenticated="true"
          :data-email="account.email"
          :data-id="account.customer"
          data-tags=""
        />
        <div
          v-if="cart"
          id="yotpo-loyalty-cart-data"
          data-free-product-points=""
          :data-cart-currency="cart.currency"
          :data-applied-coupon-points="yotpoAppliedPoints"
          :data-cart-id="cart.selection"
          data-has-paid-product="true"
          data-has-free-product="false"
        />
        <div
          v-if="cart"
          id="yotpo-loyalty-checkout-data"
          :cart-subtotal-cents="cart.totals.itemsTotalPriceAsNumber * 100"
        />
      </div>
    </client-only>
    <SearchInput :search-open="searchOpen" />

    <!-- Mobile Sidebar (Main Menu) -->
    <transition name="slide-in-left">
      <MobileSidebar
        v-show="mobileSidebarOpen"
        class="sidebar left"
        :main-menu="mainMenu"
      >
        <CountrySelector
          slot="country-selector"
          :country-code="currentCountryCode"
        />
      </MobileSidebar>
    </transition>

    <!-- Slide in Country Selector from right -->
    <transition name="slide-in-right">
      <CountrySelectorSidebar
        v-if="countrySelectorOpen"
        :countries="countriesSorted"
        :markets="markets"
        :site-lang-slug="currentSiteLangSlug"
        class="sidebar right"
      />
    </transition>

    <!-- Slide in Product Restock from right -->
    <transition name="fade">
      <ProductRestockPopup v-if="productRestockSidebarOpen" class="popup" />
    </transition>

    <!-- Slide in Add To Cart popup from top -->
    <transition name="slide-in-top">
      <AddedToCartPopup
        v-if="addedToCart.open"
        :product="addedToCart.product"
        :size="addedToCart.size"
        class="sidebar top"
      />
    </transition>

    <!-- Lowest Price Banner -->
    <transition name="slide-in-top">
      <LowestPriceBanner
        v-if="lowestPriceBanner.open"
        :message="$t('PDP_Lowest_Price_Info')"
      />
    </transition>

    <!-- Nuxt -->
    <nuxt />

    <!-- Header, needs to be below <nuxt /> to recieve settings on page level in Storyblok -->
    <Header
      :cart-qty="cartQty"
      :main-menu="mainMenu && mainMenu.content.topLevel"
      :left-menu="mainMenu && mainMenu.content.leftMenu"
      :right-menu="mainMenu && mainMenu.content.rightMenu"
    />

    <!-- Help Sidebar -->
    <transition name="slide-in-right">
      <CustomerSupportSidebar
        v-show="customerSupportOpen"
        class="sidebar right"
      />
    </transition>

    <!-- Footer -->
    <Footer>
      <CountrySelector
        slot="country-selector"
        :country-code="currentCountryCode"
      />
      <FooterMenu
        v-if="footerMenu && footerMenu.content.menus.length"
        slot="footer-menu"
        :menu="footerMenu && footerMenu.content.menus"
      />
    </Footer>

    <!-- Tinted Overlay to be used when triggering various popups and sidebars -->
    <transition name="fade">
      <Overlay v-if="overlayZIndex > 0" :z-index="overlayZIndex" />
    </transition>

    <!-- Dynamic modals are added to this container -->
    <modals-container />

    <!-- vue-notification placeholder -->
    <client-only>
      <notifications
        classes="xlash-notification"
        position="top center"
        width="100%"
      />

      <!-- <modals-container /> -->
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import dayjsDefault from 'dayjs'
import Header from '@/components/header/Header'
import MobileSidebar from '@/components/header/MobileSidebar'
import CountrySelector from '~/components/country-selector/CountrySelector'
import AddedToCartPopup from '~/components/added-to-cart-popup/AddedToCartPopup'
import LowestPriceBanner from '~/components/lowest-price-banner/LowestPriceBanner'
import CustomerSupportSidebar from '~/components/customer-support-sidebar/CustomerSupportSidebar'
import Footer from '~/components/footer/Footer.vue'
import FooterMenu from '~/components/footer-menu/FooterMenu.vue'
import initYotpoLoyaltyWidgets from '~/mixins/initYotpoLoyaltyWidgets'
import account from '~/mixins/account'
import { setupDayjs } from '~/plugins/dayjs'
import SearchInput from '~/components/search/SearchInput'

const dayjs = setupDayjs(dayjsDefault)

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    CountrySelector,
    AddedToCartPopup,
    LowestPriceBanner,
    MobileSidebar,
    Footer,
    FooterMenu,
    CustomerSupportSidebar,
    SearchInput,
  },
  mixins: [initYotpoLoyaltyWidgets, account],
  data() {
    return {
      scrolledToBottom: false,
      currentMarketInfo: {},
      version: undefined,
      versionCheckTimer: null,
      yotpoAppliedPoints: '',
      crossSellExpirationCheckerInterval: undefined,
    }
  },
  head() {
    const result = {
      script: [this.yotpoHeadScript],
    }
    const recaptchaSource = {
      src: `https://www.google.com/recaptcha/api.js?render=${this.$config.recaptchaSiteKey}`,
      async: true,
    }

    // Lipscore init script exists in app.html to guarantee it always
    // runs first and client side
    const isoLanguageCode = this.currentLanguage.iso639
    const lipscoreLanguageCode =
      {
        en: 'en',
        no: 'no',
        sv: 'se',
        da: 'dk',
        fi: 'fi',
        fr: 'fr',
        de: 'de',
        pt: 'br', // Is this Breton or Brazilian (ie portuguese?)
        br: 'br', // Is this Breton or Brazilian (ie portuguese?)
        cs: 'cz',
        it: 'it',
        es: 'es',
        pl: 'pl',
        nl: 'nl',
        ja: 'ja',
      }[isoLanguageCode] || 'en'

    result.script.push({
      src: `//static.lipscore.com/assets/${lipscoreLanguageCode}/lipscore-v1.js`,
      async: true,
    })

    result.script.push(recaptchaSource)
    return result
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.ui.search.searchOpen,
      account: (state) => state.account.account,
      countrySelectorOpen: (state) =>
        state.ui['country-selector'].countrySelectorOpen,
      cartSidebarOpen: (state) => state.ui['cart-sidebar'].cartSidebarOpen,
      overlayZIndex: (state) => state.ui.overlay.overlayZIndex,
      markets: (state) => state['centra-market'].markets,
      mobileSidebarOpen: (state) =>
        state.ui['mobile-sidebar'].mobileSidebarOpen,
      customerSupportOpen: (state) => state.ui['customer-support'].sidebarOpen,
      addedToCart: (state) => state.ui['added-to-cart'],
      lowestPriceBanner: (state) => state.ui['lowest-price-banner'],
      enableDiscountFilter: (state) => state.ui.enableDiscountFilter,
      selectedProductID: (state) => state.ui.selectedProductID,
      selectedProductFindifyData: (state) =>
        state.ui.selectedProductFindifyData,
      currentRoute: (state) => state.routes.currentRoute,
      productRestockSidebarOpen: (state) =>
        state.ui['restock-notification'].sidebarOpen,
    }),
    ...mapGetters({
      countriesSorted: 'frontend/countriesSorted',
      availableLanguages: 'frontend/availableLanguages',
      currentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentLanguage: 'frontend/currentLanguage',
      currentSiteLangSlug: 'frontend/currentSiteLangSlug',
      cart: 'centra-cart/cart',
      getMenuById: 'storyblok/getMenuById',
      settings: 'storyblok/settings',
      getProductById: 'centra-product/getPdpProductById',
      getMarketSettings: 'storyblok/getMarketSettings',
      getMarketByCountryCode: 'frontend/getMarketByCountryCode',
      crossSellIsActive: 'cross-sell/isActive',
    }),
    accountKey() {
      const accountParts = []
      if (this.account !== null && this.account.isLoggedIn) {
        accountParts.push('account')
      }
      if (this.cart) {
        accountParts.push('cart')
      }
      return accountParts.join('-')
    },
    ctaColors() {
      const settings = this.$store.getters['storyblok/settings']

      return {
        '--primary-background': settings?.addToCartBg || '#0d3fcc',
        '--primary-text': settings?.addToCartText || '#FFFFFF',
      }
    },
    mainMenu() {
      return this.getMenuById('main-menu')
    },
    footerMenu() {
      return this.getMenuById('footer-menu')
    },
    mobileBottomNavbar() {
      return this.getMenuById('mobile-bottom-navbar')
    },
    cartQty() {
      if (this.cart) {
        return this.cart.items.reduce((acc, cur) => {
          acc = cur.quantity + acc
          return acc
        }, 0)
      } else {
        return 0
      }
    },
    globalFooter() {
      if (
        this.currentMarketInfo?.footerUsps &&
        this.currentMarketInfo.footerUsps[0]
      ) {
        return this.currentMarketInfo.footerUsps[0]
      }
      return null
    },
    isPdp() {
      return this.currentRoute?.component?.component === 'product'
    },
    showCookieNotice() {
      return (
        this.settings?.cookieNoticeText &&
        this.$cookies.get('cookienotice') !== 1
      )
    },
  },
  watch: {
    $route() {
      this.$store.dispatch('ui/close-all/closeAll')
    },
    crossSellIsActive() {
      this.crossSellExpirationChecker()
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.detectBottom)
    document.body.removeEventListener(
      'yotpoLoyaltyDiscountApplied',
      this.handleLoyaltyCoupon
    )
    clearInterval(this.versionCheckTimer)

    this.removeCentraEventListener()
  },
  async mounted() {
    window.addEventListener('scroll', this.detectBottom, {
      capture: true,
      passive: true,
    })
    this.setVH()
    window.addEventListener('resize', this.setVH)

    document.body.addEventListener(
      'yotpoLoyaltyDiscountApplied',
      this.handleLoyaltyCoupon
    )

    this.ipStackRedirect()

    this.addCentraEventListener()
    document.addEventListener('centra_checkout_payment_callback', () => {
      const selectionId = this.cart.selection
      this.$store.dispatch('last-order/set', selectionId)
    })

    await this.initializeCrossSell()
    this.crossSellExpirationChecker()
  },
  created() {
    const settings = this.getMarketSettings(
      this.currentLanguageCode,
      this.getMarketByCountryCode(this.currentCountryCode)
    )
    if (settings) {
      this.currentMarketInfo = settings.data[0]
    }
  },
  unmounted() {
    this.resetCrossSellExpirationChecker()
  },
  methods: {
    ...mapMutations({
      unsetCrossSell: 'cross-sell/unset',
    }),
    ...mapActions({
      initializeCrossSell: 'cross-sell/initialize',
      getCrossSellExpiration: 'cross-sell/getExpiration',
    }),
    ipStackRedirect() {
      const geoipRedirectCode = this.$route.query.rc
      const isIpStackRedirected = !!this.$route.query.ic
      const ipStackAccessKey = this.$config.ipStackAccessKey
      if (
        window?.location &&
        // only run this on prod and stage
        (/xlash\.com$/.test(window?.location?.host) ||
          /xlash\.frackend\.net$/.test(window?.location?.host)) &&
        geoipRedirectCode &&
        ipStackAccessKey &&
        !isIpStackRedirected
      ) {
        this.$axios
          .get(`https://api.ipstack.com/check?access_key=${ipStackAccessKey}`)
          .then((response) => {
            const ipStackCountryCode = response?.data?.country_code
            if (
              ipStackCountryCode &&
              ipStackCountryCode.toLowerCase() !==
                geoipRedirectCode.toLowerCase()
            ) {
              const regexp = new RegExp(
                `^/${this.currentCountryCode.toLowerCase()}[/|$]`
              )
              const redirectPath = window.location.pathname.replace(
                regexp,
                `/${ipStackCountryCode.toLowerCase()}/`
              )
              const urlParams = new URLSearchParams(window.location.search)
              urlParams.delete('rc')
              urlParams.append('ic', ipStackCountryCode.toLowerCase())
              window.location.href = `${redirectPath}?${urlParams}`
            }
          })
          .catch((error) => {
            console.error('IP-stack error', error)
          })
      }
    },
    resetCrossSellExpirationChecker() {
      clearInterval(this.crossSellExpirationCheckerInterval)
      this.crossSellExpirationCheckerInterval = undefined
    },
    crossSellExpirationChecker() {
      if (!process.client) {
        // Only run on client
        return
      }

      if (typeof this.crossSellExpirationCheckerInterval !== 'undefined') {
        this.resetCrossSellExpirationChecker()
      }

      if (this.crossSellIsActive) {
        async function checker() {
          const expiration = await this.getCrossSellExpiration()
          if (expiration.isBefore(dayjs().utc())) {
            this.resetCrossSellExpirationChecker()
            this.unsetCrossSell()
          }
        }

        checker.call(this)

        // Check if cross sell has expired every five seconds
        this.crossSellExpirationCheckerInterval = setInterval(
          checker.bind(this),
          5000
        )
      }
    },
    addCentraEventListener() {
      document.addEventListener(
        'centra_checkout_payment_callback',
        this.centraCheckoutCallback.bind(this)
      )
    },
    removeCentraEventListener() {
      document.removeEventListener(
        'centra_checkout_payment_callback',
        this.centraCheckoutCallback.bind(this)
      )
    },
    centraCheckoutCallback(eventData) {
      const postData = eventData.detail
      const responseEventRequired = postData.responseEventRequired
      const addressIncluded = postData.addressIncluded
      this.$store
        .dispatch('centra-cart/checkoutPaymentCallback', {
          paymentMethod: postData.paymentMethod,
          address: addressIncluded
            ? postData.shippingAddress
            : shippingAddressFromCheckout(),
          // billingAddress: addressIncluded ? postData.billingAddress : billingAddressFromCheckout(),
          paymentMethodSpecificFields: postData.paymentMethodSpecificFields,
        })
        .then((data) => {
          // TODO: This should most likely be done in 'centra-cart/checkoutUpdateFields'
          // window.CentraCheckout && window.CentraCheckout.resume(eventData.detail?.additionalFields?.suspendIgnore)

          if (responseEventRequired) {
            if (data.action === 'success') {
              // Order was placed directly, redirect to result page with receipt to GET
              this.$router.push(
                this.$u(`checkout/result?receipt=${data.receiptId}`)
              )
              return
            }
            if (data.action === 'javascript') {
              const updateEvent = new CustomEvent(
                'centra_checkout_payment_response',
                { detail: data.formFields }
              )
              document.dispatchEvent(updateEvent)
            }
            if (data.action === 'redirect') {
              window.location.href = data.url
              return
            }
            if (data.errors) {
              // Payment failed for some reason, show error
              this.$notify({
                title: this.$t('Checkout_PlaceOrder_Generic_ErrorTitle'),
                text: this.$t('Checkout_PlaceOrder_Generic_ErrorMsg'),
                type: 'error',
              })
              // return
            }
            // action is javascript, send back the formFields
            // Docs don't have sendCentraEvent in them so unsure what they are? window event?
            // sendCentraEvent("centra_checkout_payment_response", response.formFields);
            // console.log(this.payment)
            // const event = new CustomEvent("centra_checkout_payment_response", { detail: this.payment.formFields });
            // document.dispatchEvent(event);
          }
        })
    },
    handleLoyaltyCoupon(event) {
      this.$store
        .dispatch('centra-cart/addVoucher', event.detail.discountCode)
        .then(() => {
          this.yotpoAppliedPoints = event.detail.costInPoints
        })
    },
    detectBottom() {
      this.scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight
    },
    closeModal() {
      this.$store.dispatch('ui/close-all/closeAll')
    },
    // Creates a css variable for 100vh that works on ios safari
    setVH() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  > header {
    order: -1;
  }

  .sidebar,
  .panel {
    position: fixed;
    z-index: 100;
    transition-property: top, transform;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

    &.left {
      // top: var(--header-complete-height);
      left: 0;
    }

    &.right {
      // top: var(--header-complete-height);
      right: 0;
    }

    &.bottom {
      bottom: 0;
      left: 0;
    }
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cookie {
    width: 100%;
  }

  .mobile-bottom-navbar {
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 0;
  }
}

[data-scrolldir='down'] {
  .mobile-bottom-navbar:not(.s-bottom) {
    transform: translateY(100%);
    transition-delay: 0.5s;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
}

// Laptop
@media screen and (min-width: $laptop) {
}
</style>
